import "../styles/index.css";

import 'flowbite';
import Datepicker from 'flowbite-datepicker/Datepicker';
import da from "flowbite-datepicker/locales/da";
Datepicker.locales.da = da.da;

// import { Carousel, Fancybox } from '@fancyapps/ui';
// import { Thumbs } from '@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js';
// import '@fancyapps/ui/dist/carousel/carousel.css';
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
// import '@fancyapps/ui/dist/carousel/carousel.thumbs.css';

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';


document.addEventListener("DOMContentLoaded", () => {
    window.Alpine = Alpine;
    Alpine.plugin(collapse);
    Alpine.plugin(focus);
    Alpine.start();

    window.toast = function(message, options = {}) {
      console.log("Toast called");
      let event = new CustomEvent('toast-show', { detail: { 
        message: message, 
        type: options.type || 'default', 
        position: options.position || 'top-center',
        description: options.description || '',
        html: options.html || ''
      }});
      window.dispatchEvent(event);
    };

    let cartContainer = document.getElementById('cartContainer');
    let cartCountElement = document.getElementById('cartCount');
    let initialCartCount = 0;

    if (cartCountElement) {
      initialCartCount = parseInt(cartCountElement.textContent.trim()) || 0;
    }
    if (cartContainer) {
      if (initialCartCount > 0) {
        cartContainer.classList.replace('hidden', 'inline-flex');
      } else {
          cartContainer.classList.add('hidden');
      }
    }
    document.body.addEventListener('htmx:afterRequest', function(event) {
      var xhr = event.detail.xhr;
      var htmxMessages = xhr.getResponseHeader('HX-Messages');
      if (htmxMessages) {
          var messages = JSON.parse(htmxMessages);
          messages.forEach(function(message) {
                window.toast(message.title, { type: message.level, description: message.description, position: 'top-center' });
          });
      }
      let itemsCount = xhr.getResponseHeader('HX-Items-Count');
      if (itemsCount !== null) {
          if (itemsCount > 0) {
            cartContainer.classList.replace('hidden', 'inline-flex');
            cartCountElement.textContent = itemsCount > 99 ? '99+' : itemsCount;
        } else {
            cartContainer.classList.replace('inline-flex', 'hidden');
            cartCountElement.textContent = '';
          }
      }
    });

    const datepickers = document.querySelectorAll('.datepicker');
    datepickers.forEach((datepickerEl) => {
        new Datepicker(datepickerEl, {
            orientation: 'bottom',
            autohide:  true,
            format: "dd/mm/yyyy",
            language: "da",
        });
    });



    // const container = document.getElementById("myCarousel");
    // const options = {
    //   Dots: false,
    //   Thumbs: {
    //     type: "classic",
    //   },
    // };

    // new Carousel(container, options, { Thumbs });


    // Fancybox.bind("[data-fancybox]", {
    //     // Your custom options
    // });
    
    // const mapRange = (inputLower, inputUpper, outputLower, outputUpper, value) => {
    //   const INPUT_RANGE = inputUpper - inputLower;
    //   const OUTPUT_RANGE = outputUpper - outputLower;
    //   return (
    //     outputLower + (((value - inputLower) / INPUT_RANGE) * OUTPUT_RANGE || 0)
    //   );
    // };
    
    // new Carousel(document.getElementById('menu_carousel'), {
    //   Dots: false,
    //   Navigation: false,
    
    //   center: false,
    //   infinite: false,
    
    //   slidesPerPage: 1,
    
    //   on: {
    //     'Panzoom.beforeTransform': (carousel) => {
    //       carousel.slides.map((slide) => {
    //         let progress =
    //           (carousel.panzoom.current.e * -1 - slide.pos) / slide.dim;
    //         progress = progress > 0 ? 1 - Math.min(1, progress) : 1;
    //         const scale = mapRange(0, 1, 0.8, 1, progress);
    
    //         slide.el.style.setProperty('--f-scale', `${scale}`);
    //         slide.el.style.setProperty('--f-progress', `${progress}`);
    //       });
    //     },
    //   },
    // }); 
        // Define the AJAX call function
    function fetchData(url, method = 'GET') {
        const headers = {
            'Content-Type': 'application/json',
            // Add other headers if necessary
        };

        return fetch(url, {
            method: method,
            headers: headers,
        })
        .then(response => {
            if (!response.ok) {

                console.log("Invalid Coupon");
                // throw new Error('Network response was not ok');
                return response.json();
            }
            return response.json();
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }
    let total_amt;
    let discountPrice=0;
    let discountamt_ln=0;
    // Example of using the fetchData function
    if ( document.querySelector('#apply-coupon')) {
    document.querySelector('#apply-coupon').addEventListener('click', () => {
       event.preventDefault(); 
        let couponCode = document.getElementById('coupon-code').value;
        total_amt = document.getElementById('total_amount').value;
        let langValue = document.documentElement.lang;
        let ln_total_amt = 0;
        if(langValue != 'en'){
            ln_total_amt = parseFloat(total_amt.replace('.', '').replace(',', '.'));
            }
        else{
            ln_total_amt = parseFloat(total_amt.replace(',', ''));
            }
        const button = document.getElementById("apply-coupon");
        fetchData(`/coupon/use-coupon/${encodeURIComponent(couponCode)}/${encodeURIComponent(ln_total_amt)}`)
            .then(data => {
                console.log(data);
                // Handle the response data
                if (data.status === "OK") {
                    // Do something with the data
                    console.log( data.discount_value, "--COuponId", data.coupon_id);
                     document.getElementById('success-message').style.display = 'none';
                     document.getElementById('error-message').style.display = 'none';
                     button. disabled = true;   
                     document.getElementById('coupon-code').value = 'Coupon code being added';
                     document.getElementById('coupon-label-code').innerText = couponCode;

                     let discount = ln_total_amt - data.discount_value;
                     let totalAmount =  parseFloat(data.discount_value).toFixed(2);  // Ensure it's formatted to 2 decimal places
                      let formattedTotal = 0;
                      if(langValue != 'en'){
                        formattedTotal = parseFloat(totalAmount).toLocaleString('da-DK', { minimumFractionDigits: 2 });
                        discountPrice = parseFloat(discount).toLocaleString('da-DK', { minimumFractionDigits: 2 });
                      } else {
                        formattedTotal = parseFloat(totalAmount).toLocaleString('en-US', { minimumFractionDigits: 2 });
                        discountPrice = parseFloat(discount).toLocaleString('en-US', { minimumFractionDigits: 2 });

                      }
                     
                     document.getElementById('coupon-value').innerText = discountPrice +' DKK';
                     document.getElementById('total_amount_display').innerText = formattedTotal +' DKK';
                     document.getElementById('btn_total_amt').innerText = formattedTotal +' DKK';
                     document.getElementById('total_amount').value = formattedTotal;
                     document.getElementById('coupon-row').classList.remove('hidden');
                     document.getElementById('discount-coupon').value = data.coupon_id;
                }
                else {
                   document.getElementById('success-message').style.display = 'none';
                   document.getElementById('error-message').innerText = data.status;
                   document.getElementById('error-message').style.display = 'block';
                }
            })
            .catch(error => {
            console.error('Error fetching data:', error);
            // Handle other errors if needed
            });
    });                                                                                                     
    document.querySelector('#remove-coupon').addEventListener('click', () => {
       event.preventDefault();
       
       let langValue = document.documentElement.lang;
       let formattedTotal = 0;
       let ln_total_amt = 0;
       let discountPrice = document.getElementById('coupon-value').innerText;
    //    let actualTotal = parseFloat(total_amt).toLocaleString('en-US', { minimumFractionDigits: 2 });
       total_amt = document.getElementById('total_amount').value;

       if(langValue != 'en'){
        discountamt_ln = parseFloat(discountPrice.replace('.', '').replace(',', '.').replace('DKK', ''));
        ln_total_amt = parseFloat(total_amt.replace('.', '').replace(',', '.'));
        formattedTotal = parseFloat(ln_total_amt+discountamt_ln).toLocaleString('da-DK', { minimumFractionDigits: 2 });
        } else {
        discountamt_ln = parseFloat(discountPrice.replace(',', '').replace('DKK', ''));
        ln_total_amt = parseFloat(total_amt.replace(',', ''));
        formattedTotal = parseFloat(ln_total_amt+discountamt_ln).toLocaleString('en-US', { minimumFractionDigits: 2 });

        }
       document.getElementById('total_amount_display').innerText = formattedTotal +' DKK';
       document.getElementById('btn_total_amt').innerText = formattedTotal +' DKK';
       document.getElementById('total_amount').value = formattedTotal;
       document.getElementById('coupon-row').classList.add('hidden');
       document.getElementById("apply-coupon").disabled = false;
       document.getElementById('coupon-code').value = '';
       document.getElementById('coupon-label-code').innerText = '';
       document.getElementById('coupon-value').innerText = 'XX';
       document.getElementById('discount-coupon').value = '';
    });
    }
    if ( document.querySelector('#review-form')) {
    document.querySelector('#review-form').addEventListener('submit', async (event) => {
        event.preventDefault();
        // let user = document.getElementById('review-user').value;
        const foodRatingElement = document.getElementById('food-rating');
        const foodStarElement = document.getElementById('food_star');
        const valueRatingElement = document.getElementById('value-rating');
        const valueStarElement = document.getElementById('value_star');
        const communicationRatingElement = document.getElementById('communication-rating');
        const communicationStarElement = document.getElementById('communctn_star');
        const expectationRatingElement = document.getElementById('expectation-rating');
        const expectationStarElement = document.getElementById('expect_star');
        foodRatingElement.value = foodStarElement.innerText.split(' ')[0];
        valueRatingElement.value = valueStarElement.innerText.split(' ')[0];
        communicationRatingElement.value = communicationStarElement.innerText.split(' ')[0];
        expectationRatingElement.value = expectationStarElement.innerText.split(' ')[0];
        const csrfToken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;
        const form = event.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData);
        console.log("data", JSON.stringify(data));
        
        // Disable star rating SVGs
        document.querySelectorAll('#review-form svg').forEach(svg => {
            svg.style.pointerEvents = 'none'; // Disable click events on SVG
        });
        form.querySelectorAll('input, button, textarea').forEach(element => {
                        element.disabled = true;
                    });
        try {
                const response = await fetch('/reviews/submit-review/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                         'X-CSRFToken': csrfToken  
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                console.log('Success:', result);
                if (result.status === "success") {

                    document.getElementById('review-success-message').style.display = 'block';
                    document.getElementById('review-btn').style.display = 'none';
                    document.getElementById('review-form').style.display = 'none';
                }
                else {
                    // Re-enable form elements on error
                    form.querySelectorAll('input, button, textarea').forEach(element => {
                        element.disabled = false;
                    });
                    // Re-enable star rating SVG
                    document.querySelectorAll('#review-form svg').forEach(svg => {
                svg.style.pointerEvents = 'auto'; // Re-enable click events on SVG
            });
                }
                // Handle success (e.g., display a success message, update the UI, etc.)
            }
        catch (error) {
            // Re-enable form elements on error
            form.querySelectorAll('input, button, textarea').forEach(element => {
                element.disabled = false;
            });
            // Re-enable star rating SVG
            document.querySelectorAll('#review-form svg').forEach(svg => {
                svg.style.pointerEvents = 'auto'; // Re-enable click events on SVG
            });
            console.error('Error:', error);
            // Handle error (e.g., display an error message)
        }
    });
    }
    if ( document.querySelector('#initiate-review-btn')) {
        document.querySelector('#initiate-review-btn').addEventListener('click', () => {
            event.preventDefault(); 
            var url = event.currentTarget.href;
            var btn = event.currentTarget; 
            // Check if button is disabled
            if (btn.disabled) {
                return; // Exit function if button is disabled
            }
            
            // Disable the button and update text
            btn.textContent = 'Initiating Review...';
            btn.disabled = true;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'Review Initiated') {
                        btn.textContent = 'Review Initiated';
                        btn.classList.remove('initiate-review-button');
                        btn.style.display = 'none';
                        document.getElementById('review-initiate-message').style.display = 'block';
                    }
                })
                .catch(error => console.error('Error initiating review:', error));
        });
    }
});

